.insta {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  border-radius: 25%;
  padding: 2px 5px 2px 5px;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
